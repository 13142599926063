import React, { useEffect, useState } from "react";
import {Col, Row} from "react-bootstrap";
import axios from "../../firebase.config";

const Article = (props) => {
    const [article, setArticle] = useState({});
    const [postUrl, setPostUrl] = useState(null);

    useEffect(() => {
        setPostUrl(props.match.params.url);
    }, [props.match.params.url]);

    //get article
    useEffect(() => {
        //console.log(postUrl);
        if (postUrl) {
            axios.get(`/posts.json?orderBy="url"&equalTo="${postUrl}"`).then(results => {
                const keys = Object.keys(results.data);
                setArticle(results.data[keys[0]]);
                //require(`./'+${article.id}`).default;
            });
        }
    }, [postUrl]);

    const loadContent = () =>  {
        return React.lazy(() =>
            import(`./${article.id}`).catch(() => import(`./Empty`))
        );
    };

    const Content = loadContent();
    
    return (
        <Row>
            <Col md={12}>
                <h1>{article.title}</h1>
                <small>{article.date}</small>
                <hr/>
                <Content />
            </Col>
        </Row>
    );
}

export default Article;

//https://www.digitalocean.com/community/conceptual_articles/react-loading-components-dynamically-hooks

