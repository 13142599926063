import React from "react";

const Services = () => {
    return (
        <div>
            <h1>Services</h1>
        </div>
    );
}

export default Services;

