import React from "react";
import { Col, Row } from "react-bootstrap";

const Footer = () => {
    const getCopyYear = () => {
        return new Date().getFullYear();
    };

    return (
        <Row>
            <Col md={12}>
                <footer >
                &copy; Copyright {getCopyYear()} &middot; <span><a href="/">suitecodes.ca</a></span>
                </footer>
            </Col>
        </Row>
    );
}

export default Footer;

