import React from "react";
import { Col, Row } from "react-bootstrap";

const About = () => {
    return (
        <Row>
            <Col md={12}>
                <div>
                    <h4>Objective</h4>
                    <p>My main objective in building this website is to share my knowledge and experience I have gain over the years to the NetSuite Community.</p>
                    <h4>Site Intentions</h4>
                    <p>The intention of this site is to provide a resource - for myself and the developer community. It is also a personal project of mine for learning web development and design, hence resulting the site to change without any notice.</p>
                    <p>If you have any questions/concerns email me @ <a href="mailto:suitecodes@gmail.com">suitecodes@gmail.com</a></p>
                </div>
            </Col>
        </Row>
    );
}

export default About;