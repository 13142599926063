import React, {useEffect, useState} from "react";
import Post from "./components/Post/Post";
import { Col, Row } from "react-bootstrap";
import axios from "./firebase.config";


import './App.css';

function App() {
    const [posts, setPosts] = useState([]);

    //get posts
    useEffect(() => {
        const fetchPosts = async() => {
            await axios.get('/posts.json').then(results => {
                setPosts(results.data);
            });
        };
        fetchPosts();
    }, []);

    const createPostCards = () => {
        return posts.map(post => {
            return <Post key={post.id} url={`/article/${post.url}`} title={post.title} abstract={post.abstract} date={post.date} author={post.author} />
        });
    }

    return (
        <div className="App">
            <Row>
                <Col md={8}>
                {
                    createPostCards()
                }
                </Col>
                <Col md={4}>
                </Col>
            </Row>

        </div>
    );
}

export default App;
