import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from "react-router-dom";

import App from './App';
import Header from "./components/Header/Header";
import Services from "./components/Services/Services";
import About from "./components/About/About";
import Article from "./components/Articles/Article";

import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import { Container } from "react-bootstrap";
import Footer from "./components/Footer/Footer";

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<div>Loading... </div>}>
            <BrowserRouter>
                <Container>
                    <Header/>
                </Container>
                <hr/>
                <Container className="mt-4">
                    <Switch>
                        <Route exact path="/" component={App} />
                        <Route path="/services" component={Services} />
                        <Route path="/about" component={About} />
                        <Route path="/article/:url" component={Article} />
                    </Switch>
                </Container>
                <hr/>
                <Container className="mt-4 mb-4">
                    <Footer/>
                </Container>
            </BrowserRouter>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);
