import React from "react";
import { Card } from "react-bootstrap";
import styles from "./Post.module.css";

const Post = (props) => {
    return (
        <Card className="mb-4 border-0">
            <Card.Body className={styles.body}>
                <Card.Title className={styles.title}>
                    <a href={props.url} className={styles.url}>
                        {props.title}
                    </a>
                </Card.Title>
                <Card.Text>
                    {props.abstract}
                </Card.Text>
                <a className={styles.readAll} href={props.url}>Read it &#187;</a>
            </Card.Body>
            <Card.Footer className={styles.footer}>
                <small>{props.date}</small>
            </Card.Footer>
        </Card>
    );
}

export default Post;